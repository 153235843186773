export const isProd =
  process.env.NODE_ENV === 'production' && !process.env.NEXT_APP_API_HOST.includes('-test.');

// App 6
export const AppType = process.env.NEXT_APP_TENANTID;

export const isDrFans = '1' == AppType;

// name
const getAppName = () => {
  switch (AppType) {
    case '6':
      return 'Dolphin Radar';
    default:
      return '';
  }
};

export const lowerName = getAppName().replace(/ /g, '_').toLowerCase();

// app logo
const getAppLogo = `/images/layouts/${lowerName}-logo.webp`;
// app net logo
const getAppNetLogo = `https://res-gpts.pumpsoul.com/logo/${lowerName}.webp`;
// app name
const getAppLogoName = `/images/layouts/${lowerName}-name.webp`;
// app ico
const getAppICO = `/${lowerName}_favicon.ico`;

// const getWebHost = `https://www.dolphinradar.com`;
// const getBlogHost = `https://www.dolphinradar.com`;

export const currentHost = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  if (window.location.host.includes('-test.')) {
    return `www-test.dolphinradar.com`;
  } else {
    return `www.dolphinradar.com`;
  }
};

const getWebHost = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  if (window.location.host.includes('localhost')) {
    return `http://localhost:3000`;
  } else if (window.location.host.includes('-test.')) {
    return `https://www-test.dolphinradar.com`;
  } else {
    return `https://www.dolphinradar.com`;
  }

  // switch (process.env.NEXT_APP_ENV) {
  //   case 'test1':
  //     return `http://localhost:3000`;
  //   // case 'testProduction':
  //   //   return `https:\/\/www-test.dolphinradar.com`;
  //   default:
  //     return `https://www.dolphinradar.com`;
  // }
};

const getBlogHost = () => {
  if (typeof window === 'undefined') {
    return '';
  }

  if (window.location.host.includes('-test.')) {
    return `https://blog-test.dolphinradar.com`;
  } else {
    return `https://blog.dolphinradar.com`;
  }

  // switch (process.env.NEXT_APP_ENV) {
  //   case 'test1':
  //     return `http://localhost:3000`;
  //   // case 'testProduction':
  //   //   return `https:\/\/blog-test.dolphinradar.com`;
  //   default:
  //     return `https://blog.dolphinradar.com`;
  // }
};

// app title
const getAppTitle = () => {
  switch (AppType) {
    case '6':
      return 'DolphinRadar | Advanced Instagram Account Monitoring & Analytics Tool';
    default:
      return '';
  }
};

// app desc
const getAppDesc = () => {
  switch (AppType) {
    case '6':
      return `Track and analyze Instagram activity with DolphinRadar. Discover hidden insights and stories behind interactions, like an Instagram spy.`;
    default:
      return ``;
  }
};

// app sub title
const getAppHeadSubTitle = () => {
  switch (AppType) {
    case '6':
      return `DolphinRadar | Advanced Instagram Account Monitoring & Analytics Tool`;
    default:
      return '';
  }
};

//
const getFirebaseConfig = () => {
  switch (AppType) {
    case '6':
      return {
        apiKey: 'AIzaSyAfXq0s9JK7QlPsoQPWaENq-7R7zYjFS30',
        // authDomain: 'dolphin-radar.firebaseapp.com',
        authDomain: `${currentHost()}/fb-auth`,
        projectId: 'dolphin-radar',
        storageBucket: 'dolphin-radar.appspot.com',
        messagingSenderId: '804901841922',
        appId: '1:804901841922:web:b5eb5af389ef24e74d0b1b',
        measurementId: 'G-VY1T84DLJK',
      };
    default:
      return {};
  }
};

interface AppSettings {
  type: number;
  name: string;
  logoUrl: string;
  logoPath: string;
  namePath: string;
  icoPath: string;
  title: string;
  headSubTitle: string;
  desc: string;
  firebaseConfig: {};
  navItems: [];
  webHost: string;
  blogHost: string;
  clarityId: string;
}

export const AppSettings = {
  type: AppType,
  name: getAppName(),
  logoUrl: getAppNetLogo,
  logoPath: getAppLogo,
  namePath: getAppLogoName,
  icoPath: getAppICO,
  title: getAppTitle(),
  headSubTitle: getAppHeadSubTitle(),
  desc: getAppDesc(),
  firebaseConfig: getFirebaseConfig(),
  webHost: getWebHost(),
  blogHost: getBlogHost(),
  clarityId: isProd ? `l9myh0u19b` : '',
};

// 首先，定义一个枚举来表示不同的页面类型
export enum PageType {
  Normal = 'normal',
  ViewInstagramStories = 'view-instagram-anonymously',
  SeeWhatSomeoneLikes = 'how-to-see-what-someone-likes-on-instagram-in-2024',
  SeeSomeoneActivity = 'how-to-see-someone-s-activity-on-instagram',
  SeeWhatSomeoneLikes2025 = 'how-to-see-what-someone-likes-on-instagram-in-2025',
  SeeSomeoneActivity2025 = 'how-to-see-someone-s-activity-on-instagram-2025',
  MonitorTeenActivity = 'monitor-your-teen-s-instagram-activity',
}
// 然后，定义一个接口来描述每种页面类型的元数据
interface PageMetadata {
  titleKey: string;
  descriptionKey: string;
  title1Key: string;
  title2Key: string;
  titleDescKey: string;
  introduces: Introduce[];
}

interface Introduce {
  titleKey: string;
  descKey: string;
  imgKey: string;
}
// 转换函数
function convertToIntroduce(item: (typeof IntroduceVisa)[0]): Introduce {
  return {
    titleKey: item.titleKey,
    descKey: item.descKey,
    imgKey: item.imgUrl, // 注意这里将 imgUrl 映射到 imgKey
  };
}

const IntroduceVisa = [
  {
    titleKey: 'introduce_title_saot',
    descKey: 'introduce_desc_saot',
    imgUrl: '/images/home/introduce/visa_saot.webp',
  },
  {
    titleKey: 'introduce_title_sbsi',
    descKey: 'introduce_desc_sbsi',
    imgUrl: '/images/home/introduce/visa_sbsi.webp',
  },
  {
    titleKey: 'introduce_title_kyfb',
    descKey: 'introduce_desc_kyfb',
    imgUrl: '/images/home/introduce/visa_kyfb.webp',
  },
  {
    titleKey: 'introduce_title_fomt',
    descKey: 'introduce_desc_fomt',
    imgUrl: '/images/home/introduce/visa_fomt.webp',
  },
];
const IntroduceHtsw = [
  {
    titleKey: 'introduce_title_kmaw',
    descKey: 'introduce_desc_kmaw',
    imgUrl: '/images/home/introduce/htsw_kmaw.webp',
  },
  {
    titleKey: 'introduce_title_gci',
    descKey: 'introduce_desc_gci',
    imgUrl: '/images/home/introduce/htsw_gci.webp',
  },
  {
    titleKey: 'introduce_title_cdwy',
    descKey: 'introduce_desc_cdwy',
    imgUrl: '/images/home/introduce/htsw_cdwy.webp',
  },
  {
    titleKey: 'introduce_title_mcs',
    descKey: 'introduce_desc_mcs',
    imgUrl: '/images/home/introduce/htsw_mcs.webp',
  },
];
const IntroduceHtss = [
  {
    titleKey: 'introduce_title_becw',
    descKey: 'introduce_desc_becw',
    imgUrl: '/images/home/introduce/htss_becw.webp',
  },
  {
    titleKey: 'introduce_title_tyiw',
    descKey: 'introduce_desc_tyiw',
    imgUrl: '/images/home/introduce/htss_tyiw.webp',
  },
  {
    titleKey: 'introduce_title_pitl',
    descKey: 'introduce_desc_pitl',
    imgUrl: '/images/home/introduce/htss_pitl.webp',
  },
  {
    titleKey: 'introduce_title_slty',
    descKey: 'introduce_desc_slty',
    imgUrl: '/images/home/introduce/htss_slty.webp',
  },
];
const IntroduceMyts = [
  {
    titleKey: 'introduce_title_siay',
    descKey: 'introduce_desc_siay',
    imgUrl: '/images/home/introduce/myts_siay.webp',
  },
  {
    titleKey: 'introduce_title_esoi',
    descKey: 'introduce_desc_esoi',
    imgUrl: '/images/home/introduce/myts_esoi.webp',
  },
  {
    titleKey: 'introduce_title_daat',
    descKey: 'introduce_desc_daat',
    imgUrl: '/images/home/introduce/myts_daat.webp',
  },
  {
    titleKey: 'introduce_title_etui',
    descKey: 'introduce_desc_etui',
    imgUrl: '/images/home/introduce/myts_etui.webp',
  },
];

const introducesVisa: Introduce[] = IntroduceVisa.map(convertToIntroduce);
const introducesHtsw: Introduce[] = IntroduceHtsw.map(convertToIntroduce);
const introducesHtss: Introduce[] = IntroduceHtss.map(convertToIntroduce);
const introducesMyts: Introduce[] = IntroduceMyts.map(convertToIntroduce);

// 创建一个映射，将页面类型与其元数据关联起来
export const pageMetadataMap: Record<PageType, PageMetadata> = {
  [PageType.Normal]: {
    titleKey: 'head_Meta_Title',
    descriptionKey: 'head_Meta_Desc',
    title1Key: 'Your All-in-One Instagram Tool',
    title2Key: 'Enhance your social insights with Dolphin Radar.',
    titleDescKey:
      "our platform automatically analyzes publicly available Instagram data so you don't waste time on manual checks. Get deeper analytics while maintaining complete privacy and platform compliance.",
    introduces: [],
  },
  [PageType.ViewInstagramStories]: {
    titleKey: 'head_Meta_Title_visa',
    descriptionKey: 'head_Meta_Desc_visa',
    title1Key: 'landing_page_visa_title_1',
    title2Key: 'landing_page_visa_title_2',
    titleDescKey: 'landing_page_visa_desc',
    introduces: introducesVisa,
  },
  [PageType.SeeWhatSomeoneLikes2025]: {
    titleKey: 'head_Meta_Title_htsw_2025',
    descriptionKey: 'head_Meta_Desc_visa_htsw',
    title1Key: 'landing_page_htsw_title_1',
    title2Key: 'landing_page_htsw_title_2_2025',
    titleDescKey: 'landing_page_htsw_desc_2025',
    introduces: introducesHtsw,
  },
  [PageType.SeeWhatSomeoneLikes]: {
    titleKey: 'head_Meta_Title_htsw',
    descriptionKey: 'head_Meta_Desc_visa_htsw',
    title1Key: 'landing_page_htsw_title_1',
    title2Key: 'landing_page_htsw_title_2',
    titleDescKey: 'landing_page_htsw_desc',
    introduces: introducesHtsw,
  },
  [PageType.SeeSomeoneActivity]: {
    titleKey: 'head_Meta_Title_htss',
    descriptionKey: 'head_Meta_Desc_visa_htss',
    title1Key: 'landing_page_htss_title_1',
    title2Key: 'landing_page_htss_title_2',
    titleDescKey: 'landing_page_htss_desc',
    introduces: introducesHtss,
  },
  [PageType.SeeSomeoneActivity2025]: {
    titleKey: 'head_Meta_Title_htss_2025',
    descriptionKey: 'head_Meta_Desc_visa_htss',
    title1Key: 'landing_page_htss_title_1',
    title2Key: 'landing_page_htss_title_2',
    titleDescKey: 'landing_page_htss_desc',
    introduces: introducesHtss,
  },
  [PageType.MonitorTeenActivity]: {
    titleKey: 'head_Meta_Title_myts',
    descriptionKey: 'head_Meta_Desc_visa_myts',
    title1Key: 'landing_page_myts_title_1',
    title2Key: 'landing_page_myts_title_2',
    titleDescKey: 'landing_page_myts_desc',
    introduces: introducesMyts,
  },
};

export const commonImgPath = `https://res-front.pumpsoul.com/ins-prod/image`;

export const HomeUpdateEmailKey = 'ins-home-update-email';

export const imageHosts = [
  'res-back-test.pumpsoul.com',
  'res-gpts-test.pumpsoul.com',
  'res-gpts.pumpsoul.com',
  'res-back.pumpsoul.com',
  'gpts-prod.s3.us-west-1.amazonaws.com',
  'www.quotesecho.com',
  'www.drfans.com',
  'www.momsgpts.com',
  'www.huntpink.com',
  'static.isme.top',
  'oaidalleapiprodscus.blob.core.windows.net',
  'res-ins-test.pumpsoul.com',
  'res-ins.pumpsoul.com',
  '*instagram*',
  'cdn.pixabay.com',
  'res-front.pumpsoul.com',
];
