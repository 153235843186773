import { Button, Space, Tooltip } from 'antd';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { InsMonitorModel } from '@/types/home';
import Image from 'next/image';
import { changeDate } from '@/shared/utils';
import { useEffect, useMemo, useState } from 'react';
import { useConfigProvider } from '@/context/ConfigProvider';
import { useRouter } from 'next/router';
import { LockFilled, QuestionCircleFilled, RightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { cn } from '@/lib/utils';

const CardUserItem = ({
  data,
  isReport = false,
  isSample = false,
  isNotActive = false,
  onClick,
  onBuyClick,
  onDetailClick,
  isActiveReport = false,
}: {
  data?: InsMonitorModel;
  isReport?: boolean;
  isSample?: boolean;
  isNotActive?: boolean;
  onClick?: (isUpgrade: boolean, data?: InsMonitorModel) => void;
  onBuyClick?: (data: any) => void;
  onDetailClick?: (data: any) => void;
  isActiveReport?: boolean;
}) => {
  const { t } = useTranslation('common');

  const renderDateInfo = () => {
    if (data?.item_name && !data?.subs_begin && !data?.subs_end) {
      return <span className='mt-1'>{data?.item_name}</span>;
    }

    return (
      <span className='mt-1'>
        {/* {t('Tracking started on')}{' '}
        {changeDate(data?.post_report_date || data?.tracking_date, 'YYYY-MM-DD')} */}
        {data?.item_name ? `${data?.item_name}: ` : ''}
        {data?.subs_begin ? changeDate(data?.subs_begin ?? '', 'YYYY-MM-DD') : ''} --{' '}
        {data?.subs_end ? changeDate(data?.subs_end ?? '', 'YYYY-MM-DD') : ''}
      </span>
    );
  };

  return (
    <div className={styles.userContent}>
      {data?.media_url && !isReport && (isNotActive || !isSample) ? (
        <a href={data?.media_url} target="_blank" referrerPolicy="no-referrer">
          {' '}
          <Image
            src={data?.avatar ?? ''}
            width={80}
            height={80}
            alt={'icon'}
            loading="lazy"
            className={styles.icon}
          />
        </a>
      ) : (
        <Image
          src={data?.avatar ?? ''}
          width={80}
          height={80}
          alt={'account icon'}
          loading="lazy"
          className={styles.icon}
        />
      )}

      <div className={styles.userInfo}>
        <div className={styles.labelDiv}>
          <h3>{data?.media_name}</h3>
          {isActiveReport ? <div>Sample Report</div> : ''}
        </div>
        {isReport && <span>{data?.profile}</span>}
        {!isReport && renderDateInfo()}

        {isSample && !isNotActive && (
          <Button
            className={classNames(styles.btn)}
            onClick={() => {
              if (onDetailClick) {
                onDetailClick(data);
              }
            }}
          >
            {t('See Analytics')}
          </Button>
        )}
        {isNotActive && (
          <Button
            className={classNames(styles.btn, styles.unlock)}
            onClick={() => {
              if (onClick) {
                onClick(false, data);
              }
            }}
          >
            <LockFilled />
            {t('Start Tracking')}
          </Button>
        )}
        {!isReport && !isSample && (
          <>
            {data?.status === 'EXPIRED' && (
              <Button
                className={classNames(styles.btn, styles.retrack)}
                onClick={() => {
                  if (onClick) {
                    onClick(false, data);
                  }
                }}
              >
                {t('Retrack')}
              </Button>
            )}
            {data?.can_upgrade &&
              data?.status !== 'EXPIRED' &&
              data?.item_id !== 2 &&
              data?.item_id !== 3 && (
                <Button
                  className={styles.btn}
                  onClick={() => {
                    if (onClick) {
                      onClick(true, data);
                    }
                  }}
                >
                  {t('Upgrade')}
                </Button>
              )}
            {(data?.status === 'NORMAL' || data?.status === 'EXPIRED') && !isSample && (
              <Button
                className={styles.btn}
                style={{ marginLeft: '5px' }}
                onClick={() => {
                  if (onBuyClick) {
                    onBuyClick(data);
                  }
                }}
              >
                {t('Get past reports')}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const CardDataItem = ({
  title,
  icon,
  value,
}: {
  title?: string;
  icon?: string;
  value?: string;
}) => {
  return (
    <div className={styles.dataContent}>
      <div className={cn(styles.dataTitle, 'mb-2')}>{title ?? ' '}</div>

      <div className={styles.dataInfo}>
        <Image
          src={icon}
          width={20}
          height={20}
          alt={'icon'}
          loading="lazy"
          className={styles.icon}
        />
        <span>{value ?? '0'}</span>
      </div>
    </div>
  );
};

const CardItem = ({
  data,
  isReport = false,
  isSample = false,
  isNotActive = false,
  onClick,
  onActionClick,
  // onDelete,
  onBuyPostClick,
  isActiveReport = false,
  locked = false,
}: {
  data?: InsMonitorModel;
  isReport?: boolean;
  isSample?: boolean;
  isNotActive?: boolean;
  onClick?: (isSeeAnalytics: boolean, data?: InsMonitorModel) => void;
  onActionClick?: (isUpgrade: boolean, data?: InsMonitorModel) => void;
  // onDelete?: (data: InsMonitorModel) => void;
  onBuyPostClick?: (data: InsMonitorModel) => void;
  isActiveReport?: boolean;
  locked?: boolean;
}) => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const [isHovered, setIsHovered] = useState(false);
  const { isMobile } = useConfigProvider();
  const disableStr = useMemo(
    () => (data?.status === 'NORMAL' || isSample ? '' : 'disable-'),
    [data?.status, isSample],
  );
  const [showBottomNextTracking, setShowBottomNextTracking] = useState(
    isMobile && data?.status === 'NORMAL' && data?.has_history && data?.next_tracking_date,
  );

  useEffect(() => {
    setShowBottomNextTracking(
      isMobile && data?.status === 'NORMAL' && data?.has_history && data?.next_tracking_date,
    );
  }, [isMobile, data]);

  const getTitleContent = useMemo(
    () => (
      <div style={{ color: 'var(--app-text-60-color)', padding: '10px' }}>{t('TooltipText')}</div>
    ),
    [],
  );

  const numList = useMemo(
    () => [
      [
        {
          title: (
            <Space>
              {t('LikesMade')}
              {!isReport && (
                <Tooltip
                  trigger={'click'}
                  zIndex={10}
                  color={'#FFF'}
                  placement="bottom"
                  title={getTitleContent}
                >
                  <QuestionCircleFilled
                    onClick={(e) => e.stopPropagation()}
                    className={styles.titleIcon}
                  />
                </Tooltip>
              )}
            </Space>
          ),
          icon: `/images/report/${disableStr}like-icon.webp`,
          value: data?.like_num,
        },
        {
          title: t('New Followings'),
          icon: `/images/report/${disableStr}following-icon.webp`,
          value: data?.following_num,
        },
        {
          title: t('Stories'),
          icon: `/images/report/${
            disableStr || data?.data_type === 'MONTH' || data?.data_type === 'WEEK'
              ? 'disable-'
              : ''
          }video-icon.webp`,
          value: data?.story_num,
        },
      ],
      [
        {
          title: t('LikedUsers'),
          icon: `/images/report/${disableStr}followed-icon.webp`,
          value: data?.like_acct_num,
        },
        {
          title: '',
          icon: `/images/report/${disableStr}verify-icon.webp`,
          value: data?.like_acct_verify_num,
        },
        {
          title: t('Highlights'),
          icon: `/images/report/${
            disableStr || data?.data_type === 'MONTH' || data?.data_type === 'WEEK'
              ? 'disable-'
              : ''
          }video-icon.webp`,
          value: data?.highlights_num,
        },
      ],
    ],
    [disableStr, data, getTitleContent, isReport],
  );

  const numListReport = useMemo(
    () => [
      [
        {
          title: (
            <Space>
              {t('LikesMade')}
              {!isReport && (
                <Tooltip
                  trigger={'click'}
                  zIndex={10}
                  color={'#FFF'}
                  placement="bottom"
                  title={getTitleContent}
                >
                  <QuestionCircleFilled
                    onClick={(e) => e.stopPropagation()}
                    className={styles.titleIcon}
                  />
                </Tooltip>
              )}
            </Space>
          ),
          icon: `/images/report/${disableStr}like-icon.webp`,
          value: data?.like_num,
        },
        {
          title: '',
          icon: `/images/report/${disableStr}picture-icon.webp`,
          value: data?.like_pic_num,
        },
        {
          title: '',
          icon: `/images/report/${disableStr}video-icon.webp`,
          value: data?.like_video_num,
        },
      ],
      [
        {
          title: t('LikedUsers'),
          icon: `/images/report/${disableStr}followed-icon.webp`,
          value: data?.like_acct_num,
        },
        {
          title: '',
          icon: `/images/report/${disableStr}verify-icon.webp`,
          value: data?.like_acct_verify_num,
        },
        {
          title: t('New Followings'),
          icon: `/images/report/${disableStr}following-icon.webp`,
          value: data?.following_num,
        },
      ],
    ],
    [disableStr, data, getTitleContent, isReport],
  );

  const getDeleteItem = useMemo(() => {
    if (isSample) {
      return <></>;
    }
    switch (data?.status) {
      case 'REMOVE':
        return (
          <div className={styles.otherContainer}>
            <span>{t('Deleted')}</span>
          </div>
        );
      case 'EXPIRED':
        return (
          <div className={styles.otherContainer}>
            <span>{t('Expired')}</span>
          </div>
        );
      default:
        return <></>;
    }
  }, [isSample, data?.status, data?.has_history, data?.next_report_show, isMobile, isHovered]);

  const getLoadingStr = () => {
    let tempLocale = router?.locale;
    // if (tempLocale !== 'es' && tempLocale !== 'pt') {
    //   tempLocale = 'en';
    // }
    return `/images/report/data-loading-${tempLocale}.gif`;
  };

  const getbackContent = useMemo(() => {
    return (
      <div
        className={cn(styles.dataBackContent, {
          'max-md:!mt-0': isReport,
        })}
        onClick={() => {
          if (onClick) {
            onClick(false, data);
          }
        }}
      >
        {
          // isReport || data?.has_history ? (
          !isReport ? (
            <div className={styles.dataListBackContent}>
              {numList.map((item, index) => {
                return (
                  <div className={styles.dataListContent} key={index}>
                    {item.map((subItem, subIndex) => {
                      const value = subItem.value > 0 || data?.report_begin ? subItem.value : '--';
                      const displayValue = locked ? '***' : value;
                      return (
                        <CardDataItem
                          key={`${index}-${subIndex}`}
                          title={subItem.title as string}
                          icon={subItem.icon}
                          value={displayValue}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={styles.dataListRow}>
              {numListReport
                .reduce((acc, cur) => {
                  return [...acc, ...cur];
                }, [])
                .map((item, index) => {
                  const value = item.value > 0 || data?.report_begin ? item.value : '--';
                  const displayValue = locked ? '***' : value;
                  return (
                    <CardDataItem
                      key={`${index}-${item.title}`}
                      title={item.title as string}
                      icon={item.icon}
                      value={displayValue}
                    />
                  );
                })}
            </div>
          )
        }
        {!isReport && <RightOutlined className={styles.arrowIcon} />}
      </div>
    );
  }, [
    isReport,
    numList,
    data?.has_history,
    data?.tracking_date,
    data?.next_report_show,
    data?.monitor_id,
    getLoadingStr(),
  ]);

  const showNextReport = useMemo(() => {
    return !isReport && !isSample && data?.status === 'NORMAL';
  }, [isReport, isSample, data?.status]);

  return (
    <div
      className={cn(styles.groundContent, {
        'max-md:!pt-0': isReport,
      })}
      onMouseEnter={() => setIsHovered(!isSample)}
      onMouseLeave={() => setIsHovered(false)}
      style={
        isReport
          ? {
              boxShadow: undefined,
              display: 'flex',
              padding: showBottomNextTracking ? `20px 0 50px 0` : 0,
              background: isReport ? undefined : `var(--foreground-rgb)`,
            }
          : {
              boxShadow: `0px 2px 12px 0px #e3e6f3`,
              padding: showBottomNextTracking ? `41px 41px 60px 41px` : '41px',
              background: isReport ? undefined : `var(--foreground-rgb)`,
            }
      }
    >
      {data?.status != undefined && !isReport && (
        <div className={styles.delete}>{getDeleteItem}</div>
      )}

      {data &&
        !isReport &&
        data?.status !== 'REMOVE' &&
        data?.status !== 'EXPIRED' &&
        !data?.checked && <div className={styles.statusTag}>{t('Report updated. Check now!')}</div>}

      {!isReport && (
        <div className={showNextReport ? styles?.reportInfoContainer : ''}>
          <CardUserItem
            data={data}
            isReport={isReport}
            isSample={isSample}
            isNotActive={isNotActive}
            onClick={onActionClick}
            onBuyClick={onBuyPostClick}
            isActiveReport={isActiveReport}
            onDetailClick={(data) => {
              if (onClick) {
                onClick(true, data);
              }
            }}
          />
        </div>
      )}

      {showNextReport && (
        <div className={styles.nextReportContainer}>
          <Image
            src={'/images/report/loading-icon.svg'}
            width={18}
            height={18}
            alt={''}
            className={styles.loading}
          />
          <span>{t('Collecting...')}</span>
        </div>
      )}

      {getbackContent}

      {!isReport && (
        <Button
          className={
            isSample || (data?.status === 'NORMAL' && data?.has_history)
              ? styles.bottomBtn
              : styles.disAbleBottomBtn
          }
          style={{ cursor: data?.has_history ? 'pointer' : 'not-allowed' }}
          onClick={() => {
            if (onClick && data?.has_history) {
              onClick(true, data);
            }
          }}
        >
          {t('See Analytics')}
        </Button>
      )}

      {/* 示例报告 */}
      {(isSample || isNotActive) && (
        <div
          className={cn('absolute top-2.5 right-2.5 text-[0.875rem] px-6 py-1.5 rounded-3xl', {
            'text-[#725CFF] bg-[#E3E6F3]': isSample,
            'text-[#9F9F9F] bg-[#EFEFEF]': isNotActive,
          })}
        >
          {isNotActive ? t('Not Active') : t('Example')}
        </div>
      )}
    </div>
  );
};

export default CardItem;
