import CardItem from '@/components/Common/CardItem';
import { EventName } from '@/shared/event-types';
import { ga_logEvent } from '@/shared/ga';
import { changeDate } from '@/shared/utils';
import { InsAccountModel, InsMonitorModel } from '@/types/home';
import { List } from 'antd';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const RecentSearchReportContent = ({ beforeInit }: { beforeInit?: () => void }) => {
  const router = useRouter();
  const [dataList, setDataList] = useState<InsMonitorModel[]>([]);

  const initComponentData = () => {
    try {
      const data = getRecentSearchReportContent();
      if (data) {
        setDataList(data);
      }
    } catch (error) {
      console.log('initComponentData error', error);
    }
  };

  useEffect(() => {
    beforeInit && beforeInit();
    initComponentData();
  }, [beforeInit]);

  return (
    <div>
      {dataList.length > 0 ? (
        <List>
          {dataList.map((item, index) => (
            <CardItem
              data={item}
              key={index}
              isSample={true}
              isNotActive={true}
              onClick={() => {
                router.push(`/analytics/not-active/${item?.media_name}`);
                // TODO: 添加新事件
                // ga_logEvent(EventName.Sample_See_Analytics);
              }}
              onActionClick={() => {
                router.push(
                  `/plan?media_name=${item.media_name}&avatar=${item.avatar}&media_url=${item.media_url}`,
                );
                // TODO: 添加新事件
                // ga_logEvent(EventName.Unlock_Bottom_StartTrackingNow_Btn)
              }}
            />
          ))}
        </List>
      ) : null}
    </div>
  );
};

export const RECENT_SEARCH_REPORT_CONTENT_KEY = 'recentSearchReportContent';
export const getRecentSearchReportContent = () => {
  try {
    const data = localStorage.getItem(RECENT_SEARCH_REPORT_CONTENT_KEY);
    return data ? (JSON.parse(data) as InsMonitorModel[]) : [];
  } catch (error) {
    console.log('getRecentSearchReportContent error', error);
    return [];
  }
};
export const setRecentSearchReportContent = (list: InsMonitorModel[]) => {
  try {
    const nowISOString = new Date().toISOString();
    const itemName = `Added ${changeDate(nowISOString, 'YYYY-MM-DD')}`;

    localStorage.setItem(
      RECENT_SEARCH_REPORT_CONTENT_KEY,
      JSON.stringify(
        list.map((item) => {
          // 临时数据设置起始时间
          item.item_name = itemName;
          return item;
        }),
      ),
    );
  } catch (error) {
    console.log('setRecentSearchReportContent error', error);
  }
};
export const hasRecentSearchReportContent = () => {
  const list = getRecentSearchReportContent();
  return list.length > 0;
};
export const removeRecentSearchReportContentByMediaNames = (mediaNames: string[]) => {
  const list = getRecentSearchReportContent();
  const newList = list.filter((item) => !mediaNames.includes(item.media_name));
  // 如果列表为空，则删除本地存储
  if (newList.length === 0) {
    localStorage.removeItem(RECENT_SEARCH_REPORT_CONTENT_KEY);
  } else {
    setRecentSearchReportContent(newList);
  }
};
export const clearRecentSearchReportContent = () => {
  localStorage.removeItem(RECENT_SEARCH_REPORT_CONTENT_KEY);
};
export const InsAccountModelToInsMonitorModel = (list: InsAccountModel[]) => {
  return list.map(
    (item) =>
      ({
        // monitor_id: item.pk,
        media_name: item.media_name,
        avatar: item.profile_pic_url,
        media_url: item.media_url,
        status: 'NORMAL',
        // identification: item.pk,
        profile: item.profile,
        checked: true,
      }) as InsMonitorModel,
  );
};

export default RecentSearchReportContent;
